<template>
  <el-card shadow="hover" class="x-card-title-slot">
    <div class="row no-gutters align-items-center" slot="header">
      <div class="col header-title"></div>
      <div class="col-auto">
        <el-button type="primary" @click="submitForm('ruleForm')" size="small">提交</el-button>
      </div>
    </div>

    <el-form
      :model="ruleForm"
      ref="ruleForm"
      :rules="rules"
      label-width="100px"
      status-icon
      style="width: 450px; margin: 0 auto"
    >
      <el-form-item label="头像" prop="face">
        <el-upload
          :action="MixinUploadApi"
          :on-success="(res) => { ruleForm.face = res.url }"
          :on-remove="() => { ruleForm.face = '' }"
          :file-list="ruleForm.face ? [{name: 'face', url: ruleForm.face}] : []"
          list-type="picture"
          :show-file-list="false"
          class="avatar-uploader"
        >
          <img
            v-if="ruleForm.face"
            :src="ruleForm.face"
            style="width:70px;height:70px;margin-right:20px"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <el-button size="mini">点击上传</el-button>
        </el-upload>
      </el-form-item>
      <el-form-item label="用户名" prop="real_name">
        <el-input v-model="ruleForm.real_name" :disabled="true" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="旧密码" prop="old_password">
        <el-input v-model="ruleForm.old_password" type="password" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="password">
        <el-input v-model="ruleForm.password" type="password" style="width: 300px"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirm_password">
        <el-input v-model="confirm_password" type="password" style="width: 300px"></el-input>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
// import * as API_goods from "@/api/goods";
// import * as API_goodsCategory from "@/api/goodsCategory";
// import {RegExp} from "@/../ui-utils";
// import Sortable from "sortablejs";
// import {UE} from "@/components";
import * as API_SystemSetting from "@/api/systemSetting";
import md5 from "js-md5";
import Storage from '@/utils/storage'
import jwt_decode from 'jwt-decode'

export default {
  name: "goodsPublish",
  components: {
    // UE,
  },
  data() {
    return {
      checkList: ["选中且禁用"],
      ruleForm: {
        face: "",
        real_name: this.$store.getters.user.username,
        old_password: "",
        password: "",
      },
      confirm_password: "",
      rules: {
        // face: [this.MixinRequired("头像不能为空！")],
        real_name: [this.MixinRequired("真实姓名不能为空！")],
        old_password: [this.MixinRequired("旧密码不能为空！")],
        password: [this.MixinRequired("新密码不能为空！")],
      },
      headSrc: this.$store.getters.user.face,
    };
  },
  created() {
    this.ruleForm.face = this.headSrc;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.confirm_password !== this.ruleForm.password) {
            this.$message.error("两次新密码输入不一致，请重新输入！");
            return;
          } else if (this.confirm_password === "") {
            this.$message.error("请输入确认密码！");
            return;
          }
          let {old_password, password} = this.ruleForm;
          this.ruleForm.old_password = md5(old_password);
          this.ruleForm.password = md5(password);
          API_SystemSetting.putAdminUsers(this.ruleForm).then((res) => {
            this.$message.success("修改成功！");
            const user = Storage.getItem('admin_user');
            user.face = res.face;
            const expires = new Date(jwt_decode(user.refresh_token).exp * 1000)
            this.$store.commit('SET_USER', {...user, expires})
          });
        }
      });
    },
    addarr() {
      this.ruleForm.Attrclass.push("");
    },
    delarr(v) {
      this.ruleForm.Attrclass.splice(v, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.avatar {
  width: 70px;
  height: 70px;
  display: block;
}
</style>
